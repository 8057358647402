import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Wrapper, Row } from './Banner.styles';
import { isOdd } from '../../utils/mixins';

const Banner = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  return (
    <Wrapper>
      {data.map((banner, index) => {
        return (
          <Row key={index}>
            <div
              className={
                isOdd(index) ? 'col col--text order2' : 'col col--text order1'
              }
            >
              <h1>{banner.titulo}</h1>
              {banner.text && HTMLReactParser(banner.text)}
            </div>

            <div className={isOdd(index) ? 'col order1' : 'col order2'}>
              <GatsbyImage
                image={
                  banner?.imagem?.localFile?.childImageSharp?.gatsbyImageData
                }
                alt={banner?.imagem?.altText}
              />
            </div>
          </Row>
        );
      })}
    </Wrapper>
  );
};

export default Banner;
