import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.full ? 'calc(100vh - 85px)' : '')};
  max-width: ${(props) => (props.full ? 'unset' : container.default)};
  width: ${(props) => (props.full ? '100%' : 'calc(100% - 2.5rem)')};
  margin: ${(props) => (props.full ? '0' : `${toRem(80)} auto`)};

  video {
    object-fit: ${(props) => (props.full ? 'cover' : 'contain')};
    width: 100%;
    height: ${(props) => (props.full ? '100%' : 'auto')};
  }
`;
