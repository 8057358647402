import React from 'react';

import { graphql } from 'gatsby';

import BannerSlider from 'components/slider';
import TextBlock from 'components/textBlock';
import ProductsGrid from 'components/productsGrid';
import Banner from 'components/banner';
import VideoBanner from '../../components/dcontent/videobanner/Videobanner';

const TemplateHomepage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        homepage: { videobanner, slide, produto, banner, introText },
      },
    },
  } = props;
  //PROPS

  const videoBanner = videobanner;
  const videoData = { videoBanner };
  return (
    <>
      {videobanner && <VideoBanner data={videoData} full />}
      <BannerSlider data={slide} />
      <TextBlock data={introText} />
      <ProductsGrid data={produto} spacing />
      <Banner data={banner} />
    </>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      homepage {
        videobanner {
          mediaItemUrl
        }
        slide {
          ... on WpPost {
            seo {
              slug
            }
            ambientes {
              thumbnailText
              nome
              thumbnailImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
        banner {
          titulo
          text
          imagem {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        introText
        produto {
          ... on WpProduto {
            slug
            seo {
              slug
            }
            product {
              text
              title
              imagem {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1
                      layout: CONSTRAINED
                      width: 300
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateHomepage;
