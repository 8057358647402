import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  width: calc(100% - 2.5rem);
  margin: ${toRem(80)} auto;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  .col {
    width: 50%;
    padding: ${toRem(50)};

    &.order1 {
      order: 1;
    }

    &.order2 {
      order: 2;
    }

    &--text {
      padding: ${toRem(60)};
    }
  }
`;
