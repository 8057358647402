import React from 'react';

import { Wrapper } from './VideoBanner.styles';

const VideoBanner = (props) => {
  //PROPS
  const {
    data: {
      videoBanner: { mediaItemUrl },
    },
    full = false,
  } = props;
  //PROPS

  return (
    <Wrapper full={full}>
      <video muted src={mediaItemUrl} autoPlay loop />
    </Wrapper>
  );
};

export default VideoBanner;
